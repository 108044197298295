<template>
  <div>
    <el-container>
      <el-header style="height: 10px"> {{ $t("title.taskCreate") }} </el-header>
      <el-main style="margin: 1px">
        <el-card shadow="always" style="height: auto">
          <!-- 第一页 -->
          <el-form ref="taskForm1" :model="taskForm" label-width="140px" label-position="left" :rules="taskForm1Rules">

            <div v-show="this.formPage == 1">
              <el-divider content-position="left">{{
                $t("step.step1")
              }}</el-divider>
              <el-row>
                <el-col :span="14" :offset="5">

                  <el-form-item :label="$t('task.taskName')" prop="taskName" required="required">
                    <el-input v-model="taskForm.taskName" style="width: 80%"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('task.serverMode')" prop="serverMode" required="required" v-show="true">
                    <el-select v-model="taskForm.serverMode" :placeholder="$t('placeholder.serverMode')"
                      style="width: 80%" clearable>
                      <el-option v-for="item in serverModes" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item :label="$t('task.cdiMode')" prop="cdiMode" required="required">
                    <el-select v-model="taskForm.cdiMode" :placeholder="$t('task.cdiMode')" style="width: 80%" clearable>
                      <el-option v-for="item in $t('cdiModes')" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item :label="$t('task.integrationMode')" prop="integrationMode" required="required">
                    <el-select v-model="taskForm.integrationMode" :placeholder="$t('task.integrationMode')"
                      style="width: 80%" clearable>
                      <el-option v-for="item in $t('integrationModes')" :key="item.value" :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item v-for="(serverUrl, index) in taskForm.serverUrls" :key="'serverUrl' + index"
                    :label="$t('task.serverUrl') + (index + 1)" :prop="'serverUrls.' + index + '.integrationUrl'"
                    required="required">
                    <el-row>
                      <el-col :span="19">
                        <el-badge
                          :value="serverUrl.integrationUrl != null && serverUrl.integrationUrl.toUpperCase().startsWith('HTTPS') ? '证书' : ''"
                          style="width: 101%">
                          <el-input :placeholder="$t('placeholder.integrationUrl')" v-model="serverUrl.integrationUrl"
                            class="input-with-select"
                            :class="{ 'is-disabled': integrationUrlShowList[index].inputDisabled }">
                            <el-select v-model="integrationUrlShowList[index].selectValue" slot="prepend"
                              placeholder="请选择" @change="integrationUrlSelectChange(index)" class="input-select-class">
                              <el-option v-for="(value, key) in urlListOptions" :label="key" :value="key"
                                :key="key"></el-option>
                              <el-option label="其它环境" value="其它环境"></el-option>
                            </el-select>
                          </el-input>
                        </el-badge>


                      </el-col>
                      <el-col :span="1" :offset="0">
                        <el-checkbox v-if="serverUrl.integrationUrl != null && serverUrl.integrationUrl
                          .toUpperCase()
                          .startsWith('HTTPS')
                          " v-model="serverUrl.useCertificate" style="margin-left: 10px"></el-checkbox>
                      </el-col>
                      <el-col :span="1" :offset="0">
                        <el-button @click="addDomain" icon="el-icon-circle-plus-outline" circle size="small" type="text"
                          v-if="index == 0">
                        </el-button>
                      </el-col>
                      <el-col :span="1" :offset="0">
                        <el-button @click.prevent="removeDomain(serverUrl)" icon="el-icon-remove-outline" circle
                          size="small" type="text" style="color: red" v-if="index != 0">
                        </el-button>
                      </el-col>
                      <el-col :span="1" :offset="0">
                        <el-button @click="testServerUrl(index)" size="small" type="text">{{ $t("button.testUrl") }}
                        </el-button>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-form>

          <!-- 第二页 -->
          <el-form ref="taskForm2" :model="taskForm" label-width="140px" label-position="left" :rules="taskForm2Rules">
            <div v-show="this.formPage == 2">
              <el-divider content-position="left">{{
                $t("step.step2")
              }}</el-divider>
              <el-row>
                <el-col :span="14" :offset="5">
                  <!-- 当对接方式为数据库时显示 -->
                  <div v-if="this.taskForm.integrationMode == 'client.database'">
                    <el-form-item :label="$t('task.databaseType')" prop="datasource.databaseType" required="required">
                      <el-select v-model="taskForm.datasource.databaseType" :placeholder="$t('task.databaseType')"
                        style="width: 80%">
                        <el-option v-for="item in $t('databaseTypes')" :key="item.value" :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('task.connectMethod')" prop="datasource.connectMethod" required="required"
                      v-if="this.taskForm.datasource.databaseType == 'ORACLE' ||
                        this.taskForm.datasource.databaseType == 'SQLSERVER'
                        ">
                      <el-select v-model="taskForm.datasource.connectMethod" :placeholder="$t('task.connectMethod')"
                        style="width: 80%">
                        <el-option v-for="item in connectMethods" :key="item.value" :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('task.databaseIp')" prop="datasource.databaseIp" required="required"
                      v-if="this.taskForm.datasource.databaseType != 'SQLITE'">
                      <el-input v-model="taskForm.datasource.databaseIp" style="width: 80%"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('task.databasePort')" prop="datasource.databasePort" required="required"
                      v-if="this.taskForm.datasource.databaseType != 'SQLITE'">
                      <el-input v-model="taskForm.datasource.databasePort" style="width: 80%"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('task.databaseName')" prop="datasource.databaseName" required="required"
                      v-if="this.taskForm.datasource.databaseType != 'SQLITE'">
                      <el-input v-model="taskForm.datasource.databaseName" style="width: 80%"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('task.databaseUsername')" prop="datasource.databaseUsername"
                      required="required" v-if="this.taskForm.datasource.databaseType != 'SQLITE'">
                      <el-input v-model="taskForm.datasource.databaseUsername" style="width: 80%"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('task.databasePassword')" prop="datasource.databasePassword"
                      v-if="this.taskForm.datasource.databaseType != 'SQLITE'">
                      <el-input v-model="taskForm.datasource.databasePassword" style="width: 80%"
                        show-password></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('task.databaseFilePath')" prop="datasource.databaseFilePath"
                      required="required" v-if="this.taskForm.datasource.databaseType == 'SQLITE'">
                      <el-input v-model="taskForm.datasource.databaseFilePath" style="width: 80%"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('task.databaseSql')" prop="datasource.databaseSql" required="required">
                      <el-input type="textarea" v-model="taskForm.datasource.databaseSql" style="width: 80%"></el-input>
                    </el-form-item>
                  </div>
                  <!-- 当对接方式为ftp或sftp时显示 -->
                  <div v-if="this.taskForm.integrationMode == 'client.ftp' ||
                    this.taskForm.integrationMode == 'server.ftp' ||
                    this.taskForm.integrationMode == 'client.sftp' ||
                    this.taskForm.integrationMode == 'server.sftp' ||
                    this.taskForm.integrationMode == 'client.local'
                    ">
                    <!-- 通用属性总会显示 -->
                    <div>
                      <el-form-item :label="$t('task.fileExtension')" prop="datasource.fileExtension" required="required">
                        <el-select v-model="taskForm.datasource.fileExtension" :placeholder="$t('task.fileExtension')"
                          style="width: 80%">
                          <el-option v-for="item in $t('fileExtensions')" :key="item.value" :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item :label="$t('task.fileCharset')" prop="datasource.fileCharset" required="required">
                        <el-input v-model="taskForm.datasource.fileCharset" style="width: 80%"></el-input>
                      </el-form-item>
                      <el-form-item :label="$t('task.customFileSplit')" prop="datasource.customFileSplit"
                        required="required" v-if="this.taskForm.datasource.fileExtension == '.csv' ||
                          this.taskForm.datasource.fileExtension == '.txt'
                          ">
                        <el-input v-model="taskForm.datasource.customFileSplit" style="width: 80%"></el-input>
                      </el-form-item>
                      <el-form-item :label="$t('task.fileFirstRow')" prop="datasource.fileFirstRow" required="required"
                        v-if="this.taskForm.datasource.fileExtension !=
                          'FIXEDLENGTH'
                          ">
                        <el-row>
                          <el-input v-model="taskForm.datasource.fileFirstRow" style="width: 80%">
                          </el-input>&emsp;
                          <el-tooltip content="不必与源文件首行相同，只需保证映射中的字段名与字段内容相符（可自定义首行字段名称），但不可重复" effect="dark">
                            <i class="el-icon-warning"></i>
                          </el-tooltip>
                        </el-row>

                      </el-form-item>
                      <el-form-item :label="$t('task.fileColumnNum')" prop="datasource.fileColumnNum" required="required">
                        <el-input v-model="taskForm.datasource.fileColumnNum" style="width: 80%"
                          @blur="fileColumnNumJudge()"></el-input>
                      </el-form-item>
                      <el-form-item :label="$t('task.fileGetType')" prop="datasource.fileGetType" required="required">
                        <el-select v-model="taskForm.datasource.fileGetType" :placeholder="$t('task.fileGetType')"
                          style="width: 80%">
                          <el-option v-for="item in $t('fileGetTypes')" :key="item.value" :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item :label="$t('task.testFileName')" prop="datasource.testFileName" required="required"
                        v-if="this.taskForm.datasource.fileGetType == 3">
                        <el-input v-model="taskForm.datasource.testFileName" style="width: 80%"></el-input>
                      </el-form-item>
                      <el-form-item :label="$t('task.filePattern')" prop="datasource.filePattern" required="required"
                        v-if="this.taskForm.datasource.fileGetType == 3">
                        <el-input v-model="taskForm.datasource.filePattern" style="width: 80%"></el-input>
                        &emsp;
                        <el-button @click="filePatternJudge()" type="text">测试</el-button>
                      </el-form-item>
                      <el-form-item :label="$t('task.filePrefix')" prop="datasource.filePrefix">
                        <el-input v-model="taskForm.datasource.filePrefix" style="width: 80%"></el-input>
                      </el-form-item>
                      <el-form-item :label="$t('task.skipFirstLine')" prop="datasource.skipFirstLine" required="required">
                        <el-select v-model="taskForm.datasource.skipFirstLine" :placeholder="$t('task.skipFirstLine')"
                          style="width: 80%">
                          <el-option v-for="item in $t('skipFirstLines')" :key="item.value" :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <!-- client ftp 显示 -->
                    <div v-if="this.taskForm.integrationMode == 'client.ftp'">
                      <el-form-item :label="$t('task.localDir')" prop="datasource.localDir" required="required">
                        <el-input v-model="taskForm.datasource.localDir" style="width: 80%"></el-input>
                      </el-form-item>
                      <el-form-item :label="$t('task.ftpClientHost')" prop="datasource.ftpClientHost" required="required">
                        <el-input v-model="taskForm.datasource.ftpClientHost" style="width: 80%"></el-input>
                      </el-form-item>
                      <el-form-item :label="$t('task.ftpClientPort')" prop="datasource.ftpClientPort" required="required">
                        <el-input v-model="taskForm.datasource.ftpClientPort" style="width: 80%"></el-input>
                      </el-form-item>
                      <el-form-item :label="$t('task.ftpClientUsername')" prop="datasource.ftpClientUsername"
                        required="required">
                        <el-input v-model="taskForm.datasource.ftpClientUsername" style="width: 80%"></el-input>
                      </el-form-item>
                      <el-form-item :label="$t('task.ftpClientPassword')" prop="datasource.ftpClientPassword"
                        required="required">
                        <el-input v-model="taskForm.datasource.ftpClientPassword" style="width: 80%"
                          show-password></el-input>
                      </el-form-item>
                      <el-form-item :label="$t('task.ftpClientPath')" prop="datasource.ftpClientPath" required="required">
                        <el-input v-model="taskForm.datasource.ftpClientPath" style="width: 80%"></el-input>
                      </el-form-item>
                    </div>
                    <!-- client sftp 显示 -->
                    <div v-if="this.taskForm.integrationMode == 'client.sftp'">
                      <el-form-item :label="$t('task.localDir')" prop="datasource.localDir" required="required">
                        <el-input v-model="taskForm.datasource.localDir" style="width: 80%"></el-input>
                      </el-form-item>
                      <el-form-item :label="$t('task.sftpClientHost')" prop="datasource.sftpClientHost"
                        required="required">
                        <el-input v-model="taskForm.datasource.sftpClientHost" style="width: 80%"></el-input>
                      </el-form-item>
                      <el-form-item :label="$t('task.sftpClientPort')" prop="datasource.sftpClientPort"
                        required="required">
                        <el-input v-model="taskForm.datasource.sftpClientPort" style="width: 80%"></el-input>
                      </el-form-item>
                      <el-form-item :label="$t('task.sftpClientUsername')" prop="datasource.sftpClientUsername"
                        required="required">
                        <el-input v-model="taskForm.datasource.sftpClientUsername" style="width: 80%"></el-input>
                      </el-form-item>
                      <el-form-item :label="$t('task.sftpClientPassword')" prop="datasource.sftpClientPassword"
                        required="required">
                        <el-input v-model="taskForm.datasource.sftpClientPassword" style="width: 80%"
                          show-password></el-input>
                      </el-form-item>
                      <el-form-item :label="$t('task.sftpClientPath')" prop="datasource.sftpClientPath"
                        required="required">
                        <el-input v-model="taskForm.datasource.sftpClientPath" style="width: 80%"></el-input>
                      </el-form-item>
                    </div>
                    <!-- server ftp 显示 -->
                    <div v-if="this.taskForm.integrationMode == 'server.ftp'">
                      <el-form-item :label="$t('task.ftpServerPassword')" prop="datasource.ftpServerPassword"
                        required="required">
                        <el-input v-model="taskForm.datasource.ftpServerPassword" style="width: 80%"
                          show-password></el-input>
                      </el-form-item>
                    </div>
                    <!-- server sftp 显示 -->
                    <div v-if="this.taskForm.integrationMode == 'server.sftp'">
                      <el-form-item :label="$t('task.sftpServerPassword')" prop="datasource.sftpServerPassword"
                        required="required">
                        <el-input v-model="taskForm.datasource.sftpServerPassword" style="width: 80%"
                          show-password></el-input>
                      </el-form-item>
                    </div>
                    <!-- 本地文件对接 显示 -->
                    <div v-if="this.taskForm.integrationMode == 'client.local'">
                      <el-form-item :label="$t('task.localDir')" prop="datasource.localDir" required="required">
                        <el-input v-model="taskForm.datasource.localDir" style="width: 80%"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-form>

          <!-- 第三页 -->
          <el-form ref="taskForm3" :model="taskForm" label-width="140px" label-position="left" :rules="taskForm3Rules">
            <div v-show="this.formPage == 3">
              <el-divider content-position="left">{{
                $t("step.step3")
              }}</el-divider>
              <el-row style="height: 50px">
                <el-col :span="2" :offset="1">
                  <el-button type="primary" size="small" @click="addField" plain>{{ $t("button.addField") }}</el-button>
                </el-col>
                <el-col :span="2">
                  <el-button type="danger" size="small" @click="deleteAllField" plain>{{ $t("button.deleteAllFields")
                  }}</el-button>
                </el-col>
                <el-col :span="4" :offset="1">
                  <i class="el-icon-s-custom"></i>&emsp;
                  <span style="font-size: 14px">{{
                    $t("button.customerField")
                  }}</span>
                </el-col>
                <el-col :span="4" :offset="5">
                  <i class="el-icon-s-shop"></i>&emsp;
                  <span style="font-size: 14px">{{
                    $t("button.prismartField")
                  }}</span>
                </el-col>
              </el-row>
              <div v-for="(field, index) in taskForm.fields" :key="'field' + index">
                <el-row>
                  <el-col :span="8" :offset="3" v-if="taskForm.datasource.fileExtension != 'FIXEDLENGTH'">
                    <el-form-item :label="$t('task.fields') + (index + 1)" :prop="'fields.' + index + '.customer'"
                      required="required" label-width="100px">
                      <el-select v-model="field.customer" filterable allow-create
                        :placeholder="$t('placeholder.customer')" style="width: 90%">
                        <el-option v-for="item in taskForm.integrationMode ==
                          'client.database'
                          ? customerDatabaseFieldOptions
                          : customerFileFieldOptions" :key="taskForm.integrationMode == 'client.database'
    ? item.name
    : item
    " :label="taskForm.integrationMode == 'client.database'
    ? item.name
    : item
    " :value="taskForm.integrationMode == 'client.database'
    ? item.name
    : item
    ">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3" :offset="3" v-if="taskForm.datasource.fileExtension == 'FIXEDLENGTH'">
                    <el-form-item :label="$t('task.fields') + (index + 1)" :prop="'fields.' + index + '.startPosition'"
                      required="required" label-width="100px">
                      <el-input v-model.number="field.startPosition" style="width: 100%"
                        :placeholder="$t('placeholder.startPosition')"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="1" :offset="1" v-if="taskForm.datasource.fileExtension == 'FIXEDLENGTH'"
                    style="margin-top: 5px">
                    <i class="el-icon-right"></i>
                  </el-col>
                  <el-col :span="3" :offset="0" v-if="taskForm.datasource.fileExtension == 'FIXEDLENGTH'">
                    <el-form-item :prop="'fields.' + index + '.endPosition'" required="required" label-width="0px"
                      style="margin-left: 5px">
                      <el-input v-model.number="field.endPosition" style="width: 70%"
                        :placeholder="$t('placeholder.endPosition')"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="2" :offset="1">
                    <span>__</span>
                  </el-col>
                  <el-col :span="7">
                    <el-form-item label-width="0px" :prop="'fields.' + index + '.hanshow'">
                      <el-select v-model="field.hanshow" clearable filterable allow-create
                        :placeholder="$t('placeholder.hanshow')" style="width: 80%"
                        @change="checkFieldIsDateTime(field.hanshow, index)">
                        <el-option v-for="item in prismartFieldOptions" :key="item.name" :label="item.nameAndType"
                          :value="item.name">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="1" :offset="0">
                    <el-button v-show="fieldInfoButtonShowList[index].show" @click="openDateFormat(index)"
                      icon="el-icon-caret-bottom" circle size="small" type="text" style="color: blue">
                    </el-button>
                  </el-col>
                  <el-col :span="1" :offset="0">
                    <el-button @click.prevent="removefield(field)" icon="el-icon-remove-outline" circle size="small"
                      type="text" style="color: red" v-if="index != 0">
                    </el-button>
                  </el-col>
                </el-row>
                <div v-if="fieldInfoButtonShowList[index].show">
                  <el-collapse-transition>
                    <div v-show="dateFormatShowList[index].show">
                      <el-row>
                        <el-col :span="8" :offset="3">
                          <el-form-item :label="$t('task.dateFormat')" label-width="100px"
                            :prop="'fields.' + index + '.dateFormat'">
                            <el-input v-model="field.dateFormat" style="width: 90%"></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </div>
                  </el-collapse-transition>
                </div>
              </div>
              <el-row align="center">
                <el-col :span="18" :offset="3">
                  <el-form-item :label="$t('taskList.convertSwitch')" label-width="100px">
                    <el-switch style="width: 91%" v-model="taskForm.convert.convertSwitch" active-color="#13ce66"
                      inactive-color="#ff4949" :active-text="$t('taskList.open')" :inactive-text="$t('taskList.close')">
                    </el-switch>
                  </el-form-item>
                  <el-form-item :label="$t('taskList.unitField')" label-width="100px"
                    v-if="this.taskForm.convert.convertSwitch">
                    <el-select v-model="taskForm.convert.unitField" placeholder="" style="width: 91%" filterable
                      clearable>
                      <el-option v-for="item in taskForm.fields" :key="item.hanshow" :label="item.hanshow"
                        :value="item.hanshow">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item :label="$t('taskList.convertFields')" label-width="100px"
                    v-if="this.taskForm.convert.convertSwitch">
                    <el-select v-model="taskForm.convert.convertFields" multiple placeholder="" style="width: 91%"
                      filterable clearable>
                      <el-option v-for="item in taskForm.fields" :key="item.hanshow" :label="item.hanshow"
                        :value="item.hanshow">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :offset="3" :span="18">
                  <el-form-item :label="$t('task.mvel')" prop="mvel" label-width="100px">
                    <el-input type="textarea" :rows="5" v-model="taskForm.mvel" style="width: 91%"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-form>

          <!-- 第四页 -->
          <el-form ref="taskForm4" :model="taskForm" label-width="140px" label-position="left" :rules="taskForm4Rules">
            <div v-show="this.formPage == 4">
              <el-divider content-position="left">{{
                $t("step.step4")
              }}</el-divider>
              <el-row style="height: 40px">
                <el-col :span="4" :offset="7">
                  <i class="el-icon-s-custom"></i>&emsp;
                  <span style="font-size: 14px">{{
                    $t("common.customerCode")
                  }}</span></el-col>
                <el-col :span="4" :offset="4">
                  <i class="el-icon-s-shop"></i>&emsp;
                  <span style="font-size: 14px">{{
                    $t("common.storeCode")
                  }}</span>
                </el-col>
              </el-row>
              <div v-for="(store, index) in taskForm.stores" :key="'store' + index">
                <el-row>
                  <el-col :span="8" :offset="4">
                    <el-form-item :label="$t('common.integrationStore') + (index + 1)"
                      :prop="'stores.' + index + '.customerCode'" required="required">
                      <el-input v-model="store.customerCode" style="width: 75%"
                        :placeholder="$t('placeholder.customerCode')"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="2">
                    <span>__</span>
                  </el-col>
                  <el-col :span="5">
                    <el-form-item label="" :prop="'stores.' + index + '.storeCode'" label-width="0px">
                      <el-input v-model="store.storeCode" style="width: 90%"
                        :placeholder="$t('placeholder.storeCode')"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="1" :offset="0">
                    <el-button @click="changeStoreInfoStatus(index)" icon="el-icon-caret-bottom" circle size="small"
                      type="text" style="color: blue">
                    </el-button>
                  </el-col>
                  <el-col :span="1" :offset="0">
                    <el-button @click="addStore" icon="el-icon-circle-plus-outline" circle size="small" type="text">
                    </el-button>
                  </el-col>
                  <el-col :span="1" :offset="0">
                    <el-button @click.prevent="removeStore(store)" icon="el-icon-remove-outline" circle size="small"
                      type="text" style="color: red" v-if="index != 0">
                    </el-button>
                  </el-col>
                </el-row>
                <el-collapse-transition>
                  <div v-show="storeInfoShowList[index].show">
                    <el-row>
                      <el-col :span="8" :offset="4">
                        <el-form-item :label="$t('common.hanshowStoreCode')"
                          :prop="'stores.' + index + '.hanshowStoreCode'">
                          <el-input v-model="store.hanshowStoreCode" style="width: 75%"
                            placeholder="HanshowStoreCode"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="2">
                        <span>__</span>
                      </el-col>
                      <el-col :span="5">
                        <el-form-item label="" :prop="'stores.' + index + '.integrationUrl'" label-width="0px">
                          <el-select v-model="store.integrationUrl" clearable multiple
                            :placeholder="$t('placeholder.integrationUrl')" style="width: 90%">
                            <el-option v-for="(item, index) in taskForm.serverUrls" :key="index"
                              :label="item.integrationUrl" :value="item.integrationUrl">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                </el-collapse-transition>
              </div>
            </div>
          </el-form>
          <!-- 第五页 -->
          <el-form ref="taskForm5" :model="taskForm" label-width="120px" label-position="left" :rules="taskForm5Rules">
            <div v-show="this.formPage == 5">
              <el-divider content-position="left">{{
                $t("step.step5")
              }}</el-divider>
              <el-row>
                <el-col :span="12" :offset="6">
                  <el-row>
                    <el-col>
                      <el-form-item :label="$t('task.dayOrCycle') + ':'" prop="dayOrCycle" required="required">
                        <el-radio-group v-model="taskForm.dayOrCycle" @input="taskForm.executeCycle = null">
                          <el-radio label="day" value="day">{{ $t("task.day") }}</el-radio>
                          <el-radio label="cycle" value="cycle">{{
                            $t("task.cycle")
                          }}</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col>
                      <el-form-item :label="$t('task.executeCycle')" v-if="this.taskForm.dayOrCycle == 'day'"
                        required="required" prop="executeCycle">
                        <el-time-picker v-model="taskForm.executeCycle" :placeholder="$t('placeholder.anyTime')"
                          value-format="HH:mm:ss" format="HH:mm:ss" style="width: 80%">
                        </el-time-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col>
                      <el-form-item :label="$t('task.executeCycle')" v-if="this.taskForm.dayOrCycle == 'cycle'"
                        required="required" prop="executeCycle">
                        <el-row>
                          <el-badge :value="executeCycleBadgeValue" :type="executeCycleBadgeType" style="width: 80%">
                            <el-select v-model="taskForm.executeCycle" clearable filterable allow-create
                              :placeholder="$t('placeholder.select')" style="width: 100%">
                              <el-option v-for="item in $t('executeCycleOptions')" :key="item.value" :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-select>
                          </el-badge>&emsp;
                          <el-tooltip content="对接频率过高会导致CPU资源长时间占用，请视服务器资源而定" effect="dark">
                            <i class="el-icon-warning"></i>
                          </el-tooltip>
                        </el-row>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row v-if="this.taskForm.dayOrCycle == 'cycle'">
                    <el-col :span="10" :offset="0">
                      <el-form-item :label="$t('task.startAndEndTime')" required="required" prop="startIntegrationTime">
                        <el-time-select style="width: 100%" placeholder="起始时间" v-model="taskForm.startIntegrationTime"
                          :picker-options="{
                            start: '00:00',
                            step: '01:00',
                            end: '24:00',
                          }">
                        </el-time-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="2" :offset="1" align="center" style="margin-top: 5px">
                      <i class="el-icon-refresh"></i>
                    </el-col>
                    <el-col :span="6" :offset="1">
                      <el-form-item label-width="0px" required="required" prop="startIntegrationTime">
                        <el-time-select style="width: 99%" placeholder="结束时间" v-model="taskForm.endIntegrationTime"
                          :picker-options="{
                            start: '00:00',
                            step: '01:00',
                            end: '24:00',
                          }">
                        </el-time-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col>
                      <el-form-item :label="$t('task.heartBeatSendTime')" required="required" prop="heartBeatSendTime">
                        <el-select v-model="taskForm.heartBeatSendTime" clearable filterable allow-create
                          :placeholder="$t('placeholder.select')" style="width: 80%">
                          <el-option v-for="item in $t('heartBeatSendTimeOptions')" :key="item.value" :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </el-form>
          <!-- 操作按钮 -->
          <el-row style="height: 50px">
            <el-col :span="2" :offset="(this.formPage == 2 &&
              this.taskForm.integrationMode == 'client.database') ||
              this.formPage == 5
              ? 7
              : 9
              ">
              <el-button type="primary" @click="previousStep()" :disabled="this.formPage == 1">
                {{ $t("button.lastStep") }}</el-button>
            </el-col>
            <el-col :span="2" :offset="2">
              <el-button type="primary" @click="nextStep()" :disabled="this.formPage == 5 ||
                (this.formPage == 2 &&
                  this.datasourceTest == false &&
                  this.taskForm.integrationMode == 'client.database')
                ">
                {{ $t("button.nextStep") }}</el-button>
            </el-col>
            <el-col :span="2" :offset="2">
              <el-button type="success" @click="createTask()" v-if="this.formPage == 5"
                v-loading.fullscreen.lock="fullscreenLoading">{{
                  this.reView == false
                  ? $t("button.createTask")
                  : $t("button.updateTask")
                }}</el-button>
            </el-col>
            <el-col :span="2" :offset="2">
              <el-button type="success" @click="testDatabase()" v-if="this.formPage == 2 &&
                this.taskForm.integrationMode == 'client.database'
                ">{{ $t("button.testUrl") }}</el-button>
            </el-col>
          </el-row>
        </el-card>
        <el-dialog :title="$t('taskList.convertTitle')" :visible.sync="convertVisible">
          <el-form :model="taskForm" align="center"> </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="convertVisible = false">{{
              $t("button.confirm")
            }}</el-button>
          </div>
        </el-dialog>
      </el-main>

      <el-footer>
        <el-row>
          <el-col :span="2" :offset="4">
            <span>{{ $t("common.percentage") }}</span>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-progress :percentage="percentage" :color="customColors"></el-progress>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import i18n from "../../i18n";
import CryptoJS from "crypto-js";
export default {
  name: "",
  data() {
    var validateTaskName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(i18n.t("message.taskNameTips")));
      } else {
        callback();
      }
    };
    var validateServerMode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(i18n.t("message.serverModeTips")));
      } else {
        callback();
      }
    };
    var validateIntegrationMode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(i18n.t("message.integrationModeTips")));
      } else {
        callback();
      }
    };
    var validatecdiMode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(i18n.t("message.cdiModeTips")));
      } else {
        callback();
      }
    };
    return {
      taskForm1Rules: {
        taskName: [{ validator: validateTaskName, trigger: "blur" }, { pattern: /^[^-\u4e00-\u9fa5]+$/, message: i18n.t("message.illgelTips") },
        { pattern: /^[\w]+$/, message: i18n.t("message.illgelTips") }],
        serverMode: [{ validator: validateServerMode, trigger: "change" }],
        cdiMode: [{ validator: validatecdiMode, trigger: "change" }],
        integrationMode: [
          { validator: validateIntegrationMode, trigger: "change" },
        ],
      },
      taskForm2Rules: {},
      taskForm3Rules: {},
      taskForm4Rules: {},
      taskForm5Rules: {},
      originalTaskName: "",
      convertVisible: false,
      datasourceTest: false,
      reView: false,
      taskForm: {
        taskName: null,
        serverMode: null,
        cdiMode: "increment",
        integrationMode: null,
        serverUrls: [
          {
            integrationUrl: null,
            callbackUrl: "",
            useCertificate: false,
          },
        ],
        datasourceType: null,
        datasource: {
          ftpClientPort: null,
          ftpClientHost: null,
          ftpClientPath: null,
          ftpClientUsername: null,
          ftpClientPassword: null,
          sftpClientPort: null,
          sftpClientHost: null,
          sftpClientPath: null,
          sftpClientUsername: null,
          sftpClientPassword: null,
          ftpServerPassword: null,
          sftpServerPassword: null,
          downloadAddress: null,
          databaseType: null,
          databaseDriver: null,
          connectMethod: null,
          databaseUrl: null,
          databaseIp: null,
          databasePort: null,
          databaseName: null,
          databaseUsername: null,
          databasePassword: null,
          databaseFilePath: null,
          databaseSql: null,
          databaseSqlCondition: null,
          databaseLimit: null,
          localDir: null,
          customFileSplit: null,
          filePrefix: null,
          fileExtension: null,
          testFileName: null,
          skipFirstLine: null,
          filePattern: null,
          fileGetType: null,
          fileFirstRow: null,
          fileColumnNum: null,
          fileCharset: "UTF-8",
        },
        stores: [
          {
            customerCode: null,
            storeCode: null,
            hanshowStoreCode: "",
            integrationUrl: [],
          },
        ],
        fields: [
          {
            customer: null,
            hanshow: null,
            dateFormat: null,
            startPosition: null,
            endPosition: null,
          },
        ],
        convert: {
          convertSwitch: false,
          unitField: null,
          convertFields: [],
        },
        fieldMap: "",
        customerTag: null,
        mvel: null,
        dayOrCycle: "cycle",
        executeCycle: null,
        startIntegrationTime: "0:00",
        endIntegrationTime: "0:00",
        heartBeatSendTime: "30",
        integrationStatus: false,
      },
      storeInfoShowList: [
        {
          show: false,
        },
      ],
      fieldInfoButtonShowList: [
        {
          show: false,
        },
      ],
      dateFormatShowList: [
        {
          show: false,
        },
      ],
      // defaultValueShowList: [
      //   {
      //     show: false,
      //   },
      // ],
      integrationUrlShowList: [
        {
          selectValue: '其它环境',
          inputDisabled: false
        },
      ],
      formPage: 1,
      serverModes: [
        {
          value: "PRISMART220RC6",
          label: "Prismart-2.2.0-rc.6+",
        },
        {
          value: "PRISMART",
          label: "Prismart-1.0.0-rc.1+",
        },
        {
          value: "SHOPWEB",
          label: "ShopWeb-1.7+",
        },
      ],
      connectMethods: [
        {
          value: "SID",
          label: "SID",
        },
        {
          value: "SERVICENAME",
          label: "SERVICENAME",
        },
        {
          value: "TNSNAME",
          label: "TNSNAME",
        },
        {
          value: "INSTANCENAME",
          label: "INSTANCENAME",
        },
        {
          value: "PORT",
          label: "PORT",
        },
      ],
      prismartFieldOptions: [],
      fieldOptions: {},
      urlListOptions: {},
      customerDatabaseFieldOptions: [],
      customerFileFieldOptions: [],
      percentage: 20,
      customColors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      pageValidator: false,
      executeCycleBadgeValue: '',
      executeCycleBadgeType: 'success',
      fullscreenLoading: false
    };
  },
  watch: {
    "taskForm.serverMode": {
      handler() {
        this.prismartFieldOptions = this.fieldOptions[this.taskForm.serverMode]
      }
    },
    "taskForm.executeCycle": {
      handler() {
        if (this.taskForm.dayOrCycle == 'cycle') {
          if (this.taskForm.executeCycle == 1) {
            this.executeCycleBadgeValue = '危险';
            this.executeCycleBadgeType = 'danger';
          } else if (this.taskForm.executeCycle == 3) {
            this.executeCycleBadgeValue = '一般';
            this.executeCycleBadgeType = 'warning';
          } else {
            this.executeCycleBadgeValue = '推荐';
            this.executeCycleBadgeType = 'success';
          }
        } else {
          this.executeCycleBadgeValue = '';
          this.executeCycleBadgeType = '';
        }
      }
    },
    "taskForm.datasource.fileFirstRow": {
      handler() {
        if (this.taskForm.integrationMode != "client.database") {
          if (
            this.taskForm.datasource.fileExtension == ".xls" ||
            this.taskForm.datasource.fileExtension == ".xlsx"
          ) {
            if (this.taskForm.datasource.fileFirstRow != "") {
              this.customerFileFieldOptions =
                this.taskForm.datasource.fileFirstRow.trim().split("|");
            }
          } else {
            if (
              this.taskForm.datasource.customFileSplit != "" &&
              this.taskForm.datasource.customFileSplit != null &&
              this.taskForm.datasource.fileFirstRow != "" &&
              this.taskForm.datasource.fileFirstRow != null
            ) {
              this.customerFileFieldOptions =
                this.taskForm.datasource.fileFirstRow
                  .trim()
                  .split(this.taskForm.datasource.customFileSplit);
            }
          }
        }
      },
    },
    "taskForm.datasource.customFileSplit": {
      handler() {
        if (
          this.taskForm.integrationMode != "client.database" &&
          this.taskForm.datasource.fileExtension != "FIXEDLENGTH"
        ) {
          if (
            this.taskForm.datasource.customFileSplit != "" &&
            this.taskForm.datasource.customFileSplit != null &&
            this.taskForm.datasource.fileFirstRow != "" &&
            this.taskForm.datasource.fileFirstRow != null
          ) {
            this.customerFileFieldOptions =
              this.taskForm.datasource.fileFirstRow
                .trim()
                .split(this.taskForm.datasource.customFileSplit);
          }
        }
      },
    }
  },

  methods: {
    fileColumnNumJudge() {
      if (
        this.taskForm.integrationMode != "client.database" &&
        this.taskForm.datasource.fileExtension != "FIXEDLENGTH"
      ) {
        if (
          this.taskForm.datasource.fileColumnNum != "" &&
          this.taskForm.datasource.fileColumnNum != null
        ) {
          if (this.customerFileFieldOptions.length !=
            this.taskForm.datasource.fileColumnNum) {
            this.$notify({
              message: i18n.t("message.fileColumnNum"),
              type: "error",
            });
          } else {
            this.$notify({
              message: i18n.t("message.fileColumnNumSuccess"),
              type: "success",
            });
          }
        }
      }
    },
    integrationUrlSelectChange(index) {
      if (this.urlListOptions.hasOwnProperty(this.integrationUrlShowList[index].selectValue)) {
        this.taskForm.serverUrls[index].integrationUrl = this.urlListOptions[this.integrationUrlShowList[index].selectValue];
        this.taskForm.serverUrls[index].useCertificate = true;
        this.integrationUrlShowList[index].inputDisabled = true;
      } else {
        this.taskForm.serverUrls[index].integrationUrl = null;
        this.integrationUrlShowList[index].inputDisabled = false;
        this.taskForm.serverUrls[index].useCertificate = false;
      }
    },
    //aes加密算法
    encrypt(word) {
      var key = CryptoJS.enc.Utf8.parse("59c432a830ac3cddd1b0013ae8d4e4e3");
      var srcs = CryptoJS.enc.Utf8.parse(word);
      var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      return "{AES}" + encrypted.toString();
    },
    //解密
    decrypt(word) {
      if (word.startsWith("{AES}")) {
        word = word.substring(5);
        var key = CryptoJS.enc.Utf8.parse("59c432a830ac3cddd1b0013ae8d4e4e3");
        var decrypt = CryptoJS.AES.decrypt(word, key, {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        });
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
      } else {
        return word;
      }
    },
    // 上一步
    previousStep() {
      this.formPage = this.formPage - 1;
      this.percentage = this.percentage - 20;
    },
    validatorMethod(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.pageValidator = true;
        } else {
          this.pageValidator = false;
        }
      });
    },
    // 下一步
    nextStep() {
      if (this.formPage < 5 && this.percentage < 100) {
        this.validatorMethod("taskForm" + this.formPage);
        if (this.pageValidator == true) {
          if (
            this.formPage == 2 &&
            this.taskForm.integrationMode == "client.database"
          ) {
            if (this.datasourceTest == true) {
              this.formPage = this.formPage + 1;
              this.percentage = this.percentage + 20;
            } else {
              this.$notify({
                message: i18n.t("message.testDatasource"),
                type: "error",
              });
            }
            this.taskForm.datasource.fileExtension = "";
          } else {
            this.formPage = this.formPage + 1;
            this.percentage = this.percentage + 20;
          }
          // this.formPage = this.formPage + 1;
          // this.percentage = this.percentage + 20;
        }
      }
    },
    initFieldMap() {
      if (this.taskForm.integrationMode != "client.database") {
        this.taskForm.fieldMap = "";
        if (this.taskForm.datasource.fileExtension == "FIXEDLENGTH") {
          this.taskForm.fields.forEach((element, index) => {
            element.customer = element.hanshow;
            this.taskForm.fieldMap +=
              element.customer + ":" + (index + 1) + ",";
          });
        } else {
          this.customerFileFieldOptions.forEach((element, index) => {
            this.taskForm.fieldMap += element + ":" + (index + 1) + ",";
          });
        }

        this.taskForm.fieldMap = this.taskForm.fieldMap.slice(
          0,
          this.taskForm.fieldMap.length - 1
        );
      }
    },
    // 创建任务
    createTask() {
      this.$refs["taskForm5"].validate((valid) => {
        if (valid) {
          return new Promise((resolve, reject) => {
            this.fullscreenLoading = true;
            this.taskForm.datasourceType = this.taskForm.datasource.databaseType;
            this.initFieldMap();
            var postBody = this.taskForm;
            if (postBody.integrationMode == "client.ftp") {
              postBody.datasource.ftpClientPassword = this.encrypt(
                this.taskForm.datasource.ftpClientPassword
              );
            }
            if (postBody.integrationMode == "server.ftp") {
              postBody.datasource.ftpServerPassword = this.encrypt(
                this.taskForm.datasource.ftpServerPassword
              );
            }
            if (postBody.integrationMode == "client.sftp") {
              postBody.datasource.sftpClientPassword = this.encrypt(
                this.taskForm.datasource.sftpClientPassword
              );
            }
            if (postBody.integrationMode == "server.sftp") {
              postBody.datasource.sftpServerPassword = this.encrypt(
                this.taskForm.datasource.sftpServerPassword
              );
            }
            if (postBody.integrationMode == "client.database") {
              postBody.datasource.databasePassword = this.encrypt(
                this.taskForm.datasource.databasePassword
              );
            }

            this.$httpList
              .createOrUpdateTask(postBody, this.originalTaskName)
              .then((res) => {
                if (res.resultCode == 1001) {
                  if (res.data == "FAILED") {
                    this.$notify({
                      message: i18n.t("message.createTaskRepeat"),
                      type: "error",
                    });
                  } else {
                    this.$notify({
                      message:
                        this.reView == false
                          ? i18n.t("message.createTaskSuccess")
                          : i18n.t("message.updateTaskSuccess"),
                      type: "success",
                    });
                    // 跳转任务列表界面
                    this.$router.push({
                      name: "taskList",
                    });
                  }
                } else {
                  this.$notify({
                    message:
                      this.reView == false
                        ? i18n.t("message.createTaskFailed")
                        : i18n.t("message.updateTaskFailed"),
                    type: "error",
                  });
                }
                this.fullscreenLoading = false;
                resolve(res);
              })
              .catch((err) => {
                reject(err);
                this.fullscreenLoading = false;
              });

          });
        }
      });

    },
    // 添加对接地址
    addDomain() {
      this.taskForm.serverUrls.push({
        integrationUrl: null,
        callbackUrl: "",
        useCertificate: false,
      });
      this.integrationUrlShowList.push({
        selectValue: "其它地址",
        inputDisabled: false
      })
    },
    // 移除对接地址
    removeDomain(item) {
      var index = this.taskForm.serverUrls.indexOf(item);
      if (index !== -1) {
        this.taskForm.serverUrls.splice(index, 1);
        this.integrationUrlShowList.splice(index, 1);
      }
    },
    //测试对接地址是否可用
    testServerUrl(index) {
      if(this.taskForm.serverUrls[index].integrationUrl == ""){
        this.$notify.error(i18n.t("message.testUrlFailed"));
        return;
      }
      let json = {
        url: this.taskForm.serverUrls[index].integrationUrl,
      };
      return new Promise((resolve, reject) => {
        this.$httpList
          .testServerUrl(json)
          .then((res) => {
            if (res.resultCode == 1001) {
              this.$notify.success(i18n.t("message.testUrlSuccess"));
            } else {
              this.$notify.error(i18n.t("message.testUrlFailed"));
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 移除字段映射
    removefield(item) {
      var index = this.taskForm.fields.indexOf(item);
      if (index !== -1) {
        this.taskForm.fields.splice(index, 1);
        this.fieldInfoButtonShowList.splice(index, 1);
        this.dateFormatShowList.splice(index, 1);
        // this.defaultValueShowList.splice(index, 1);
      }
    },
    // 增加字段
    addField() {
      this.taskForm.fields.push({
        customer: "",
        hanshow: "",
        dateFormat: null,
        // defaultValue: null,
        startPosition: null,
        endPosition: null,
      });
      this.fieldInfoButtonShowList.push({
        show: false,
      });
      this.dateFormatShowList.push({
        show: true,
      });
      // this.defaultValueShowList.push({
      //   show: true,
      // });
    },
    // 移除所有字段映射
    deleteAllField() {
      this.taskForm.fields = [
        {
          customer: null,
          hanshow: null,
          dateFormat: null,
          startPosition: null,
          endPosition: null,
        },
      ];
      this.fieldInfoButtonShowList = [
        {
          show: false,
        },
      ];
      this.dateFormatShowList = [
        {
          show: false,
        },
      ];
    },
    // 打开日期格式设置的下拉框
    openDateFormat(index) {
      this.dateFormatShowList[index].show =
        !this.dateFormatShowList[index].show;
    },
    // 增加门店
    addStore() {
      this.taskForm.stores.push({
        customerCode: "",
        storeCode: "",
        hanshowStoreCode: "",
        integrationUrl: [],
      });
      this.storeInfoShowList.push({
        show: false,
      });
    },
    // 移除门店
    removeStore(item) {
      var index = this.taskForm.stores.indexOf(item);
      if (index !== -1) {
        this.taskForm.stores.splice(index, 1);
        this.storeInfoShowList.splice(index, 1);
      }
    },
    // 获取prismart标准字段
    getPrismartFieldList() {
      return new Promise((resolve, reject) => {
        this.$httpList
          .getPrismartFieldList()
          .then((res) => {
            this.fieldOptions = res.data;
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 更改门店信息下拉框可见性
    changeStoreInfoStatus(index) {
      this.storeInfoShowList[index].show = !this.storeInfoShowList[index].show;
    },
    // 检查字段是否为datetime类型，如果是则显示下拉按钮
    checkFieldIsDateTime(name, index) {
      this.prismartFieldOptions.forEach((item) => {
        if (item.name == name) {
          if (item.type == "datetime") {
            this.fieldInfoButtonShowList[index].show = true;
          } else {
            this.fieldInfoButtonShowList[index].show = false;
          }
        }
      });
    },
    //回显任务列表中的任务
    echoTask() {
      //先初始化prismart字段列表
      return new Promise((resolve, reject) => {
        this.$httpList
          .getPrismartFieldList()
          .then((res) => {
            this.fieldOptions = res.data;
            //如果传递的参数不为空，那么进行回显
            var localStorageTask = localStorage.getItem("task");
            if (localStorageTask != "" && localStorageTask) {
              this.reView = true;
              this.storeInfoShowList = [];
              this.fieldInfoButtonShowList = [];
              this.dateFormatShowList = [];
              this.integrationUrlShowList = [];
              this.taskForm = JSON.parse(localStorageTask).task;
              this.prismartFieldOptions = this.fieldOptions[this.taskForm.serverMode]
              this.originalTaskName = this.taskForm.taskName;
              this.taskForm.stores.forEach(() => {
                this.storeInfoShowList.push({
                  show: false,
                });
              });
              this.taskForm.fields.forEach((field) => {
                this.dateFormatShowList.push({
                  show: true,
                });
                this.judgeTypeByName(field.hanshow);
              });
              this.taskForm.serverUrls.forEach((item) => {
                var selectValue = '其它环境'
                var disabled = false
                for (const key in this.urlListOptions) {
                  if (this.urlListOptions[key] == item.integrationUrl) {
                    selectValue = key;
                    disabled = true;
                  }
                }
                this.integrationUrlShowList.push({
                  selectValue: selectValue,
                  inputDisabled: disabled
                })
              });

              this.decryptEcho(this.taskForm);
              localStorage.setItem("task", "");
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    judgeTypeByName(name) {
      var showOption = false;
      this.prismartFieldOptions.forEach((item) => {
        if (item.name == name && item.type == "datetime") {
          showOption = true;
        }
      });
      this.fieldInfoButtonShowList.push({
        show: showOption,
      });
    },
    //获取数据库字段
    getDataBaseField() {
      return new Promise((resolve, reject) => {
        this.$httpList
          .getCustomerData(this.taskForm)
          .then((res) => {
            this.customerDatabaseFieldOptions = res.data.customerDataList;
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    testDatabase() {
      return new Promise((resolve, reject) => {
        this.taskForm.datasourceType = this.taskForm.datasource.databaseType;
        this.$httpList
          .testDatabase(this.taskForm)
          .then((res) => {
            if (res.resultCode == 1001) {
              this.$notify({
                message: i18n.t("message.testDataBaseSuccess"),
                type: "success",
              });
              this.getDataBaseField();
              this.datasourceTest = true;
            } else {
              this.$notify({
                message: i18n.t("message.testDataBaseFailed"),
                type: "error",
              });
              this.datasourceTest = false;
            }

            resolve(res);
          })
          .catch((err) => {
            reject(err);
            this.$notify({
              message: i18n.t("message.testDataBaseFailed"),
              type: "error",
            });
            this.datasourceTest = false;
          });
      });
    },
    //校验正则表达式
    filePatternJudge() {
      if (
        this.taskForm.datasource.testFileName != null &&
        this.taskForm.datasource.testFileName != "" &&
        this.taskForm.datasource.filePattern != null &&
        this.taskForm.datasource.filePattern != ""
      ) {
        this.$notify({
          message:
            "storeCode : " +
              this.taskForm.datasource.testFileName.match(
                this.taskForm.datasource.filePattern
              )[0] == null ? "" : this.taskForm.datasource.testFileName.match(
                this.taskForm.datasource.filePattern
              )[0],
          type: "success",
        });
      }
    },
    decryptEcho(taskForm) {
      if (taskForm.integrationMode == "client.ftp") {
        this.taskForm.datasource.ftpClientPassword = this.decrypt(
          taskForm.datasource.ftpClientPassword
        );
      }
      if (taskForm.integrationMode == "server.ftp") {
        this.taskForm.datasource.ftpServerPassword = this.decrypt(
          taskForm.datasource.ftpServerPassword
        );
      }
      if (taskForm.integrationMode == "client.sftp") {
        this.taskForm.datasource.sftpClientPassword = this.decrypt(
          taskForm.datasource.sftpClientPassword
        );
      }
      if (taskForm.integrationMode == "server.sftp") {
        this.taskForm.datasource.sftpServerPassword = this.decrypt(
          taskForm.datasource.sftpServerPassword
        );
      }
      if (taskForm.integrationMode == "client.database") {
        this.taskForm.datasource.databasePassword = this.decrypt(
          taskForm.datasource.databasePassword
        );
      }
    },
    urlList() {
      return new Promise((resolve, reject) => {
        this.$httpList
          .getIntegrationUrl()
          .then((res) => {
            this.urlListOptions = res.data;
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  created() {
    this.urlList();
    this.echoTask();
  },
};
</script>

<style>
.input-with-select .el-input-group__prepend {
  background-color: #fff;
  width: 25%;
}

.input-with-select .el-input__inner {
  border-radius: 0;
}

.el-form-item__content .el-input .el-input__inner {
  border-radius: 0;
}

.input-with-select.is-disabled .el-input__inner {
  pointer-events: none;
  user-select: none;
}</style>